import { ChangeEvent } from "react";
import ctl from "@netlify/classnames-template-literals";

interface ICheckbox {
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled?: boolean;
  size?: "small" | "large";
  checkedColor?: "green" | "blue";
}

const Checkbox = ({
  checked = false,
  onChange,
  value,
  id,
  disabled = false,
  size = "small",
  checkedColor = "green",
}: ICheckbox) => {
  const CheckboxWrapperCN = ctl(`
    before:content[''] peer relative 
    ${size === "small" ? "h-[1.25rem] w-[1.25rem]" : "h-[1.5rem] w-[1.5rem]"}
    appearance-none rounded-full border border-solid border-n-300 bg-transparent 
    transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 
    before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity 
    hover:scale-105 hover:before:opacity-0
    ${
      checkedColor === "green" &&
      "before:bg-g-300 checked:border-g-300 checked:bg-g-300 checked:before:bg-g-300"
    }
    ${
      checkedColor === "blue" &&
      "before:bg-b-300 checked:border-b-300 checked:bg-b-300 checked:before:bg-b-300"
    }
    ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
  `);

  const CheckboxIconCN = ctl(`
    absolute text-white transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100  -translate-y-2/4 -translate-x-2/4
    ${size === "small" && "top-2/4 left-2/4"}
    ${size === "large" && "top-[65%] left-[55%]"}
  `);

  return (
    <div className="inline-flex items-center">
      <label
        className="relative flex items-center rounded-full cursor-pointer"
        htmlFor={id}
        data-testid={`${id}-checkbox`}
      >
        <input
          type="checkbox"
          className={CheckboxWrapperCN}
          checked={checked}
          name={id}
          value={value}
          id={id}
          onChange={(e) => {
            !disabled && onChange && onChange(e);
          }}
          disabled={disabled}
        />
        <span className={CheckboxIconCN} data-testid={`${id}-check-icon`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={
              size === "small" ? "h-3.5 w-3.5" : "h-[1.25rem] w-[1.25rem]"
            }
            viewBox={size === "small" ? "0 0 20 20" : "0 0 24 24"}
            fill="currentColor"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
