import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import ctl from "@netlify/classnames-template-literals";

import SVGIcon from "../design-system/SVGIcon";

interface IToastMessage {
  warning: boolean;
  showNotif: boolean;
  message: string;
}

const ToastMessage = ({ warning, showNotif, message }: IToastMessage) => {
  const sharedToast = document.getElementById(`shared-toast`);
  const [firstRender, setFirstRender] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const toastCN = ctl(`
    fixed right-[1.5rem] bottom-0 w-fit
    inline-flex
    ${!isVisible ? "invisible " : ""}
    p-[1rem] border border-solid border-[0.25rem] z-[60]
    ${warning ? "bg-r-100 border-r-300" : "bg-b-100 border-b-300"}
    ${showNotif ? "fade-in" : "fade-out"}
    rounded-[0.625rem]
  `);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    setTimeout(
      () => {
        setIsVisible(showNotif);
      },
      showNotif ? 0 : 5000
    );
  }, [showNotif, firstRender]);

  return (
    sharedToast &&
    ReactDOM.createPortal(
      <div className={toastCN} style={{ transform: "translate(0%, -50%)" }}>
        <div className="flex items-center mr-[1rem]">
          <SVGIcon
            iconName={warning ? "icon-cross" : "icon-check"}
            size={32}
            fillColor={warning ? "var(--r-400)" : "var(--g-400)"}
            customClass="mr-[1rem]"
          />
          <p className="typography-h300-bold whitespace-pre-line text-n-700 min-w-[19.75rem] max-w-[calc(100vw-32rem)]">
            {message}
          </p>
        </div>
        <div className="flex gap-[1rem] items-center">
          <p
            className="typography-h300-bold whitespace-pre-line text-n-700 text-center cursor-pointer"
            onClick={() => setIsVisible(false)}
          >
            Close
          </p>
          <SVGIcon
            iconName="icon-cancel"
            size={20}
            fillColor="var(--n-700)"
            onClick={() => setIsVisible(false)}
          />
        </div>
      </div>,
      sharedToast
    )
  );
};

export default ToastMessage;

export interface INotifData {
  warning?: boolean;
  message?: string | null;
}
