import React from "react";

import ctl from "@netlify/classnames-template-literals";

interface SVGIconProps {
  iconName: string;
  id?: string;
  customClass?: string;
  size?: number;
  fillColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  viewBox?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
}

const SVGIcon = ({
  id,
  customClass,
  size,
  iconName,
  fillColor = "var(--p-400)",
  onClick,
  viewBox,
  width,
  height,
  disabled = false,
  onMouseDown,
}: SVGIconProps) => {
  const _width = width || size;
  const _height = height || size;
  const btnCN = ctl(`
    ${customClass}
    ${onClick && (disabled ? "cursor-not-allowed" : "cursor-pointer")}
  `);

  return (
    <span
      id={id || "svg-" + iconName}
      data-testid={id || "svg-" + iconName}
      className={btnCN}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
        onClick && !disabled && onClick(e)
      }
      onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
        onMouseDown && !disabled && onMouseDown(e)
      }
      style={{
        height: `${_height}px`,
        width: `${_width}px`,
      }}
    >
      <svg
        width={`${_width}px`}
        height={`${_height}px`}
        viewBox={viewBox ? viewBox : "0 0 24 24"}
        fill={fillColor}
      >
        <use
          xlinkHref={`/assets/image/general.svg#${iconName}`}
          href={`/assets/image/general.svg#${iconName}`}
        />
      </svg>
    </span>
  );
};

export default SVGIcon;
