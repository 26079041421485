import { isNaN } from "lodash";
import isEmpty from "lodash/isEmpty";
import LocationFilterDropdown from "../components/LocationFilterDropdown";
import CountrySearchDropdown from "../pages/manage/search-dropdown/CountrySearchDropdown";

export const isWrongFormatEmail = (email: string) => {
  const Regex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !Regex.test(email);
};

const validateField = (field: any, value: string | number | boolean) => {
  const { isRequired, validation, length, maximumLength, minimumLength } =
    field || {};

  if (validation === "toggle") return null;
  // if (value === "") return null;
  const isNumber = typeof value === "number";
  const isString = typeof value === "string";

  const stringifyValue = isNumber ? value?.toString() : value;

  const isEmptyField = isNumber
    ? value === 0 || isNaN(value)
    : isEmpty(stringifyValue);

  if (isEmptyField && isRequired) {
    return "This field is required";
  }
  if (
    validation === "email" &&
    isString &&
    !isEmptyField &&
    isWrongFormatEmail(value)
  )
    return `Oops..wrong format, please check again`;
  if (
    !!maximumLength &&
    !!minimumLength &&
    !!value &&
    isString &&
    (value?.length > maximumLength || value?.length < minimumLength)
  )
    return `Please input this field between ${minimumLength} - ${maximumLength} characters`;
  if (!!maximumLength && isString && !!value && value?.length > maximumLength)
    return `Please input this field maximum ${maximumLength} characters`;
  if (!!length && isString && !!value && value?.length < length)
    return `Please input this field minimal ${length} characters`;
  return null;
};

const listFieldLocation = (index?: number) => [
  {
    key: "locationName",
    label: "Location name",
    isRequired: true,
    maximumLength: 30,
    onTab: () =>
      document.getElementById(`location-filter-dropdown+${index}`)?.click(),
  },
  {
    key: "type",
    label: "Location Type",
    type: "custom",
    children: <LocationFilterDropdown index={index} />,
    isRequired: true,
    widthClass: "w-[24.875rem]",
    onSuccess: () => {
      document.getElementById(`address+${index}`)?.focus();
    },
  },
  {
    key: "address",
    label: "Address",
    isRequired: true,
  },
  {
    key: "state",
    label: "State",
  },
  {
    key: "place",
    label: "City",
    isRequired: true,
  },
  {
    key: "postcode",
    label: "Zip Code",
    isRequired: true,
    maximumLength: 10,
    onTab: () =>
      document.getElementById(`country-search-dropdown+${index}`)?.click(),
  },
  {
    key: "country",
    label: "Country",
    isRequired: true,
    type: "custom",
    children: <CountrySearchDropdown id={`country-search-dropdown+${index}`} />,
    wrapperCustomClass: "w-[24.75rem] mr-[24.75rem]",
  },
  {
    key: "isDefault",
    type: "checkbox",
    label: "Make this default location",
    size: "large",
    checkedColor: "blue",
  },
];

const getCurrencySymbol = (currencyCode: string) => {
  return (0)
    .toLocaleString(navigator.language, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

export { validateField, listFieldLocation, getCurrencySymbol };
