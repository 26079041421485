import { useState } from "react";
import InputField from "../../design-system/InputField";
import Checkbox from "../../design-system/Checkbox";
import Button from "../../design-system/Button";

const Register = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    username: "",
    verifyPassword: "",
  });
  const [isRememberMe, setIsRememberMe] = useState(false);

  return (
    <div className="flex">
      <img
        src="/assets/image/login-background.png"
        className="w-[50vw] max-h-[100vh] object-cover"
        alt="login"
      />
      <div className="overflow-y-auto h-[100vh] w-[50vw] flex justify-center">
        <div className="flex flex-col gap-[1.5rem] w-[32.125rem] h-fit py-[5rem]">
          <p className="typography-h500-bold text-n-700">Let’s Get Started</p>
          <div className="flex flex-col gap-[0.25rem]">
            <p className="typography-h300-bold text-n-700">Email</p>
            <InputField
              value={loginData?.email}
              onChange={(val: string) =>
                setLoginData({ ...loginData, email: val })
              }
              placeholder="johndoe@email.com"
              customClass="w-full"
              id="email"
            />
          </div>
          <div className="flex flex-col gap-[0.25rem]">
            <p className="typography-h300-bold text-n-700">Username</p>
            <InputField
              value={loginData?.username}
              onChange={(val: string) =>
                setLoginData({ ...loginData, username: val })
              }
              placeholder="johndoe"
              customClass="w-full"
              id="username"
            />
          </div>
          <div className="flex flex-col gap-[0.25rem] w-[32.125rem]">
            <p className="typography-h300-bold text-n-700">Password</p>
            <InputField
              value={loginData?.password}
              onChange={(val: string) =>
                setLoginData({ ...loginData, password: val })
              }
              placeholder="Password"
              customClass="w-full"
              type="password"
              id="password"
            />
          </div>
          <div className="flex flex-col gap-[0.25rem] w-[32.125rem]">
            <p className="typography-h300-bold text-n-700">Verify Password</p>
            <InputField
              value={loginData?.verifyPassword}
              onChange={(val: string) =>
                setLoginData({ ...loginData, verifyPassword: val })
              }
              placeholder="Password"
              customClass="w-full"
              type="password"
              id="verify-password"
            />
          </div>
          <p className="typography-h300-bold text-b-400 underline cursor-pointer">
            Forgot Password
          </p>
          <div className="flex gap-[0.5rem]">
            <Checkbox
              id="remember-me"
              value="remember-me"
              checked={isRememberMe}
              onChange={() => setIsRememberMe(!isRememberMe)}
            />
            <p className="typography-h300-bold text-n-700">Remember Me</p>
          </div>
          <Button id="login" customClass="w-full h-[3.875rem]">
            Log in
          </Button>
          <p className="typography-h300-bold text-n-700 text-center mt-[-0.5rem]">
            I do not have an account yet{" "}
            <span className="typography-h300-bold text-b-400 underline cursor-pointer">
              Register Here
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
