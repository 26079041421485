import React, { useState } from "react";

import ctl from "@netlify/classnames-template-literals";

import useClickOutside from "../hooks/useClickOutside";
import SVGIcon from "../design-system/SVGIcon";

import {
  IContext,
  IInlineDialog,
  IMenuItem,
  IMenuItems,
  ITrigger,
} from "./interface/InlineDialogInterface";
import FloatingComponent from "./FloatingComponent";
import TooltipContainer from "./TooltipContainer";

const DialogContext = React.createContext<IContext | null>(null);
DialogContext.displayName = "DialogContext";

export const useInlineDialog = () => React.useContext(DialogContext);

function InlineDialog({
  children,
  customClass = "",
  isBlock,
  withPosition = true,
  useHover = false,
  id,
}: IInlineDialog) {
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLHeadingElement>(null);

  useClickOutside(ref, () => {
    setOpen && setOpen(false);
  });

  const contextValue = {
    open,
    setOpen: () => setOpen(!open),
    ref,
  };

  const dialogCN = ctl(`
    ${customClass ? customClass : ""}
    ${withPosition ? "relative" : ""}
    ${isBlock ? "block" : "inline-block"} text-left
  `);

  const onHover = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!useHover) return;
    setOpen && setOpen(!open);
  };

  return (
    <DialogContext.Provider value={contextValue}>
      <div
        ref={ref}
        className={dialogCN}
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        data-testid={id}
      >
        {children}
      </div>
    </DialogContext.Provider>
  );
}

export const useDialog = () => React.useContext(DialogContext);

const DEFAULT_MAX_HEIGHT = 12;

const MenuItemsWrapper = (props: any) => {
  const context = useDialog();
  const { open: rootOpen } = context || {};

  // eslint-disable-next-line no-restricted-globals
  const isOpen = props?.open ?? rootOpen;

  if (!isOpen) return null;

  return (
    <div className="relative">
      <FloatingComponent
        widthBasedOnChildren={props?.widthBasedOnChildren}
        customContentHeight={DEFAULT_MAX_HEIGHT * 16}
      >
        <MenuItemContent {...props} />
      </FloatingComponent>
    </div>
  );
};

function MenuItemContent({
  children,
  position = "right",
  vPosition = "bottom",
  options,
  customClass = "",
  maxHeightWrapper = DEFAULT_MAX_HEIGHT,
  minHeightWrapper,
  flexDirectionClass = "",
  widthBasedOnChildren = false,
}: IMenuItems) {
  const localRef = React.useRef<HTMLHeadingElement>(null);

  const MenuCN = ({
    position,
    vPosition,
    customClass,
  }: {
    position?: "left" | "middle" | "right";
    vPosition?: "top" | "bottom" | "relative" | "relative-to-bottom";
    customClass: string;
  }) => {
    return ctl(`
      absolute
      z-10
      mt-[0.125rem]
      origin-top-right
      items-center
      rounded-[0.25rem]
      bg-n-000
      shadow-raised
      ${customClass && customClass}
      ${vPosition === "relative" && "top-0"}
      ${vPosition === "relative-to-bottom" && "bottom-0"}
      ${vPosition === "top" && "top-[-1.5rem] -translate-y-full"}
      ${vPosition === "bottom" && "bottom-0 translate-y-full"}
      ${position === "left" && "left-0"}
      ${position === "right" && "right-0"}
      ${position === "middle" && "left-0 -translate-x-1/2"}
      ${widthBasedOnChildren ? "!w-fit" : "w-full"}
      `);
  };

  return (
    <div
      className={MenuCN({ position, vPosition, customClass })}
      ref={localRef}
    >
      <div
        id="inline-dialog-wrapper"
        className={`overflow-x-hidden py-[1px] overflow-y-auto flex flex-wrap ${flexDirectionClass} ${
          widthBasedOnChildren ? "w-fit" : ""
        }`}
        style={{
          maxHeight: `${maxHeightWrapper}rem`,
          minHeight: `${minHeightWrapper}rem`,
        }}
      >
        {options
          ? options?.map(({ ...props }, index) => (
              <MenuItem
                {...props}
                key={index}
                index={index}
                data-testid={`option-${index}`}
              />
            ))
          : children}
      </div>
    </div>
  );
}

function MenuItem({
  children,
  selected = false,
  onClick,
  customClass = "",
  disabled = false,
  index,
  useHover = true,
}: IMenuItem) {
  const isDisabled = disabled && !selected;

  const MenuItemCN = (customClass: string) => {
    return ctl(`
      menu-item
      flex 
      items-center
      justify-between
      ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
      ${useHover && (isDisabled ? "bg-n-001" : "hover:bg-b-100")}
      ${selected && "bg-b-100"}
      ${customClass}
      :focus-visible:bg-b-100
      w-full
    `);
  };

  const { setOpen } = useDialog() || {};

  const _onClick = (e: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    onClick && !isDisabled && onClick(e);
    setOpen && setOpen();
  };

  return (
    <li
      onClick={_onClick}
      className={MenuItemCN(customClass)}
      id={`inline-dialog-item-${index}`}
      data-testid={`inline-dialog-item-${index}`}
      tabIndex={index}
    >
      <div
        className="flex w-full items-center justify-between"
        data-cy="inline-dialog-item"
      >
        {children && children}
      </div>
    </li>
  );
}

function Trigger({
  children,
  disabled,
  dataCy,
  customClass,
  useArrow,
  onClick,
  disabledText,
  id,
}: ITrigger) {
  const triggerCN = ctl(`
    relative
    ${!disabled ? "cursor-pointer" : "cursor-not-allowed"}
    ${disabled ? "bg-n-100" : "bg-n-000"}
    ${customClass}
  `);

  const { open, setOpen } = useDialog() || {};

  const _onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!disabled) {
      onClick && onClick();
      setOpen && setOpen();
    }
  };

  return (
    <div
      className={triggerCN}
      onClick={_onClick}
      data-cy={dataCy}
      data-testid={`${id}-trigger`}
    >
      <TooltipContainer show={!!disabled} text={disabledText}>
        <div className="flex gap-[0.5rem] items-center h-full justify-between">
          {children}

          {useArrow && (
            <SVGIcon
              iconName={open ? "icon-arrow-up" : "icon-arrow-down"}
              size={16}
              fillColor="var(--n-300)"
            />
          )}
        </div>
      </TooltipContainer>
    </div>
  );
}

InlineDialog.displayName = "InlineDialog";

InlineDialog.Trigger = Trigger;
InlineDialog.MenuItem = MenuItem;
InlineDialog.MenuItems = MenuItemsWrapper;

export default InlineDialog;
