import { useReducer, useEffect } from "react";
import useKeyPress from "../hooks/useKeyPress";
import capitalize from "lodash/capitalize";
import InlineDialog, { useDialog } from "../design-system/InlineDialog";

const initialState = { selectedIndex: null };

const InlineDialogKeyPress = ({
  list,
  onChange,
  keyList,
  useCapitalize,
  isForcedSelect = false,
  menuCustomClass = "",
  widthBasedOnChildren = false,
}: any) => {
  const { setOpen, open } = useDialog() || {};

  const reducer = (state: any, action: any) => {
    if (!list) return;
    switch (action.type) {
      case "arrowUp":
        return {
          selectedIndex:
            state.selectedIndex !== 0 && state.selectedIndex !== null
              ? state.selectedIndex - 1
              : list?.length - 1,
        };
      case "arrowDown":
        return {
          selectedIndex:
            state.selectedIndex !== list?.length - 1 &&
            state.selectedIndex !== null
              ? state.selectedIndex + 1
              : 0,
        };
      case "reset":
        return {
          selectedIndex: null,
        };
      case "forced_select":
        return {
          selectedIndex: 0,
        };
      default:
        throw new Error();
    }
  };

  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (arrowUpPressed && open) {
      dispatch({ type: "arrowUp" });
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed && open) {
      dispatch({ type: "arrowDown" });
    }
  }, [arrowDownPressed]);

  useEffect(() => {
    if (enterPressed && open) {
      if (!list) return;
      const selectedSize = list?.[state?.selectedIndex];
      onChange(selectedSize);
      setOpen && setOpen();
    }
  }, [enterPressed]);

  useEffect(() => {
    if (open) {
      dispatch({ type: isForcedSelect ? "forced_select" : "reset" });
    }
  }, [open]);

  return (
    <>
      {list?.length === 0 && (
        <InlineDialog.MenuItem disabled>
          <p className="typography-h200 text-n-700 px-[0.625rem] py-[0.5rem] w-full">
            No Option
          </p>
        </InlineDialog.MenuItem>
      )}
      {list?.length > 0 &&
        list?.map((listItem: any, index: number) => (
          <InlineDialog.MenuItem
            key={index}
            useHover={false}
            onClick={() => onChange(listItem)}
            selected={state?.selectedIndex === index}
            index={index}
            customClass={menuCustomClass}
          >
            <p
              className={`typography-h200 text-n-700 px-[0.625rem] py-[0.5rem] hover:bg-b-100 w-full  ${
                widthBasedOnChildren ? "whitespace-nowrap" : ""
              }`}
            >
              {useCapitalize
                ? capitalize(keyList ? listItem[keyList] : listItem)
                : keyList
                ? listItem[keyList]
                : listItem}
            </p>
          </InlineDialog.MenuItem>
        ))}
    </>
  );
};

export default InlineDialogKeyPress;
