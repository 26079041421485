import { ReactNode } from "react";

import ctl from "@netlify/classnames-template-literals";

import "./RadioButton.scss";

const RadioButton = ({
  isActive,
  setIsActive,
  children,
  disabled,
  id,
}: {
  isActive: boolean;
  setIsActive?: (act: boolean) => void;
  children?: ReactNode;
  disabled?: boolean;
  id?: string;
}) => {
  const RadioCN = ctl(`
    radio 
    w-[1.25rem] h-[1.25rem]
    rounded-full appearance-none
    focus:outline-none 
    ${!isActive && "border border-[0.125rem] border-solid border-n-200"}
    ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
  `);

  return (
    <div
      className="flex items-center"
      onClick={() => !disabled && setIsActive && setIsActive(!isActive)}
    >
      <input
        checked={isActive}
        id={id || "radio-button"}
        type="radio"
        value=""
        name={id || "default-radio"}
        className={RadioCN}
        data-testid={`${id}-radio`}
      />
      <label htmlFor="radio-button" />
      {children && (
        <label
          htmlFor="radio-button"
          className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
          data-testid={`${id}-label`}
        >
          {children}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
