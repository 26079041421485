// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/_mixins.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/_base.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/_typography.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/_animation.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  -webkit-overflow-scrolling: none;
  min-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

p {
  margin: 0;
}

:focus-visible {
  outline: 0;
}

.no-scroll {
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    width: 0px;
  }

  scrollbar-width: none;
}

img[src=""],
img:not([src]) {
  visibility: hidden;
}

.number-input::placeholder,
.input-field::placeholder {
  font-family: "Poppins";
  line-height: 1.3125rem;
  font-weight: 400;
  font-size: 0.875rem;
}

.svg-container {
  overflow: hidden;

  .active-svg {
    filter: drop-shadow(0px 100px 0 var(--p-600));
    transform: translateY(-100px);
  }

  .inactive-svg {
    filter: drop-shadow(0px 100px 0 var(--n-000));
    transform: translateY(-100px);
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAKA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;;;EAGE,SAAS;EACT,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA,qBAAqB;AACvB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;;EAEE,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;;EAEhB;IACE,6CAA6C;IAC7C,6BAA6B;EAC/B;;EAEA;IACE,6CAA6C;IAC7C,6BAA6B;EAC/B;AACF;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,oBAAoB;EACpB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":["@import \"./styles/_mixins.scss\";\n@import \"./styles/_base.scss\";\n@import \"./styles/_typography.scss\";\n@import \"./styles/_animation.scss\";\n\nbody {\n  -webkit-overflow-scrolling: none;\n  min-height: 100%;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\niframe#webpack-dev-server-client-overlay {\n  display: none;\n}\n\n*,\n*::after,\n*::before {\n  margin: 0;\n  padding: 0;\n  box-sizing: inherit;\n}\n\np {\n  margin: 0;\n}\n\n:focus-visible {\n  outline: 0;\n}\n\n.no-scroll {\n  ::-webkit-scrollbar {\n    width: 0px;\n  }\n\n  ::-webkit-scrollbar-track {\n    width: 0px;\n  }\n\n  scrollbar-width: none;\n}\n\nimg[src=\"\"],\nimg:not([src]) {\n  visibility: hidden;\n}\n\n.number-input::placeholder,\n.input-field::placeholder {\n  font-family: \"Poppins\";\n  line-height: 1.3125rem;\n  font-weight: 400;\n  font-size: 0.875rem;\n}\n\n.svg-container {\n  overflow: hidden;\n\n  .active-svg {\n    filter: drop-shadow(0px 100px 0 var(--p-600));\n    transform: translateY(-100px);\n  }\n\n  .inactive-svg {\n    filter: drop-shadow(0px 100px 0 var(--n-000));\n    transform: translateY(-100px);\n  }\n}\n\ninput::-webkit-contacts-auto-fill-button {\n  visibility: hidden;\n  display: none !important;\n  pointer-events: none;\n  position: absolute;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
