import { useEffect, useState } from "react";

const useKeyPress = (targetKey: any, id?: string) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = (e: any) => {
      e.preventDefault();
      if (e.key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = (e: any) => {
      e.preventDefault();
      if (e.key === targetKey) {
        setKeyPressed(false);
      }
    };

    if (id) {
      document.getElementById(id)?.addEventListener("keydown", downHandler);
      document.getElementById(id)?.addEventListener("keyup", upHandler);
    } else {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
    }

    return () => {
      if (id) {
        document
          .getElementById(id)
          ?.removeEventListener("keydown", downHandler);
        document.getElementById(id)?.removeEventListener("keyup", upHandler);
      } else {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      }
    };
  }, [targetKey]);

  return keyPressed;
};

export default useKeyPress;
