import { useEffect, useState } from "react";

import capitalize from "lodash/capitalize";
import ctl from "@netlify/classnames-template-literals";

import InlineDialog from "../../design-system/InlineDialog";
import InlineDialogKeyPress from "../../components/InlineDialogKeyPress";
import RadioButton from "../../design-system/RadioButton";
import Shimmer from "../../design-system/Shimmer";
import SVGIcon from "../../design-system/SVGIcon";
import useKeyPress from "../../hooks/useKeyPress";
import TooltipContainer from "../../design-system/TooltipContainer";

const SelectedTag = ({
  name,
  removeSelected,
  id,
}: {
  name: string;
  removeSelected: () => void;
  id?: string;
}) => {
  return (
    <div
      className="p-[0.25rem] flex gap-[0.625rem] w-fit items-center justify-content bg-n-100 rounded-[0.25rem] mt-[0.25rem]"
      data-testid={id}
    >
      <p className="typography-h200-bold text-n-700" data-testid={`${id}-text`}>
        {name}
      </p>
      <SVGIcon
        iconName="icon-cancel"
        size={16}
        fillColor="var(--n-300)"
        onClick={removeSelected}
      />
    </div>
  );
};
interface IDropdown {
  list: any;
  selected?: any;
  onChange?: (type: any) => void;
  label?: string;
  isRequired?: boolean;
  widthClass?: string;
  typeView?: string;
  title?: string;
  isLoading?: boolean;
  id?: string;
  keyList?: string;
  tabIndex?: number;
  maxSelection?: number;
  disabled?: boolean;
  customChange?: (type: any) => void;
  widthWrapperClass?: string;
  useCapitalize?: boolean;
  currentIndex?: number | null;
  isHorizontal?: boolean;
  labelCustomClass?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  useRemoveButton?: boolean;
  menuCustomClass?: string;
  widthBasedOnChildren?: boolean;
}

interface IItemDropdown {
  id?: string;
  name?: string;
  organizationName?: string;
  code?: string;
}

const FilterDropdown = ({
  list,
  selected,
  onChange,
  label,
  isRequired,
  widthClass = "w-[15.5rem]",
  typeView = "dropdown",
  title,
  isLoading,
  id,
  keyList,
  tabIndex,
  maxSelection = 1,
  disabled,
  customChange,
  widthWrapperClass,
  useCapitalize = false,
  currentIndex = null,
  isHorizontal = false,
  labelCustomClass,
  errorMessage,
  showErrorMessage,
  useRemoveButton = false,
  menuCustomClass,
  widthBasedOnChildren = false,
}: IDropdown) => {
  const [currentSelected, setCurrentSelected] = useState<any>(null);
  const isMax = currentSelected?.length === maxSelection;
  const isOneSelectionOnly = maxSelection === 1;
  const currentId = currentIndex !== null ? `${id}+${currentIndex}` : id;
  const isAlreadySelect = maxSelection === 1 && currentSelected;

  const getSelected = (selected: any) => {
    return keyList ? selected?.[keyList] : selected;
  };

  const changeFunction = (sel: any) => {
    if (customChange) {
      customChange(sel);
      return;
    }

    onChange && onChange(sel);
  };

  const _onChange = (sel: any) => {
    if (maxSelection === 1) {
      const _selected = getSelected(sel);
      setCurrentSelected(_selected);
      changeFunction(sel);
      return;
    }
    const selectedIds = currentSelected?.map((sel: any) => sel.id);
    if (selectedIds?.includes(sel?.id)) {
      removeSelected(sel?.id);
      return;
    }
    changeFunction([...(currentSelected || []), sel]);
    setCurrentSelected([...(currentSelected || []), sel]);
  };

  const removeSelected = (removedId: string) => {
    changeFunction(
      currentSelected?.filter((tag: any) => tag?.id !== removedId) || []
    );
    setCurrentSelected(
      currentSelected?.filter((tag: any) => tag?.id !== removedId) || []
    );
  };

  const tabPressed = useKeyPress("Tab", currentId);

  useEffect(() => {
    tabPressed &&
      currentId &&
      document.activeElement === document.getElementById(currentId) &&
      document.getElementById(currentId)?.click();
  }, [tabPressed]);

  useEffect(() => {
    const _selected = isOneSelectionOnly ? getSelected(selected) : selected;
    setCurrentSelected(_selected);
  }, [selected]);

  const labelCN = ctl(`
    typography-h200-bold text-n-700 mb-[0.25rem]
    ${isHorizontal && !labelCustomClass && "w-[7.25rem]"}
    ${isHorizontal && !isRequired && "ml-[0.5rem]"}
    ${labelCustomClass}
  `);

  return (
    <div className={widthWrapperClass || ""}>
      <div
        className={`${widthWrapperClass ? "w-full" : ""} ${
          isHorizontal ? "flex gap-[0.75rem] items-center" : ""
        }`}
      >
        <div className="flex gap-[0.25rem] items-center h-fit">
          {isRequired && label && (
            <div
              className="h-[0.5rem] w-[0.5rem] rounded-full bg-r-400 mb-[0.25rem]"
              data-testid={`${currentId}-required-icon`}
            />
          )}
          {label && (
            <p className={labelCN} data-testid={`${currentId}-label`}>
              {label}
            </p>
          )}
        </div>
        <div
          className={widthWrapperClass ? "w-full" : ""}
          data-testid={currentId}
        >
          {typeView === "dropdown" ? (
            <>
              <InlineDialog customClass={widthWrapperClass ? "w-full" : ""}>
                <InlineDialog.Trigger
                  customClass={`border border-solid rounded-[0.75rem] p-[0.625rem] bg-n-000 ${widthClass} ${
                    errorMessage ? "border-r-400" : "border-n-300"
                  }`}
                  useArrow
                  disabled={(!isOneSelectionOnly && isMax) || disabled}
                  disabledText={`Maximum ${maxSelection} option selected`}
                >
                  <div
                    className={`flex justify-between items-center ${
                      isAlreadySelect ? "w-[calc(100%-24px)]" : ""
                    }`}
                  >
                    <TooltipContainer
                      show={isAlreadySelect}
                      text={
                        useCapitalize
                          ? capitalize(currentSelected || "")
                          : currentSelected || ""
                      }
                      customClass={
                        useRemoveButton ? "w-[calc(100%-24px)]" : "w-full"
                      }
                    >
                      <p
                        id={currentId}
                        tabIndex={tabIndex}
                        className={`${
                          isAlreadySelect && !disabled
                            ? "text-n-700"
                            : "text-n-300"
                        }  typography-h200 truncate`}
                      >
                        {isAlreadySelect
                          ? useCapitalize
                            ? capitalize(currentSelected || "")
                            : currentSelected || ""
                          : title}
                      </p>
                    </TooltipContainer>

                    {isAlreadySelect && useRemoveButton && (
                      <SVGIcon
                        iconName="icon-cancel"
                        size={16}
                        fillColor="var(--n-300)"
                        onClick={(e) => {
                          e.stopPropagation();
                          _onChange(null);
                        }}
                      />
                    )}
                  </div>
                </InlineDialog.Trigger>
                <InlineDialog.MenuItems
                  widthBasedOnChildren={widthBasedOnChildren}
                  vPosition="bottom"
                >
                  {isLoading ? (
                    <Shimmer customClass="m-[0.5rem]" heightClass="h-[1rem]" />
                  ) : (
                    <InlineDialogKeyPress
                      list={list}
                      onChange={_onChange}
                      useCapitalize={useCapitalize}
                      keyList={keyList}
                      menuCustomClass={menuCustomClass}
                      widthBasedOnChildren={widthBasedOnChildren}
                    />
                  )}
                </InlineDialog.MenuItems>
              </InlineDialog>
              {errorMessage && showErrorMessage && (
                <p
                  className="typography-h100 text-r-400 mt-[0.25rem]"
                  data-testid={`${currentId}-error-message`}
                >
                  {errorMessage}
                </p>
              )}
            </>
          ) : (
            <div className="flex gap-[0.75rem] wrap">
              {list?.map((type: any) => (
                <RadioButton
                  isActive={selected === (keyList ? type[keyList] : type)}
                  setIsActive={() => onChange && onChange(type)}
                >
                  <p
                    className="typography-h200-bold text-n-700 ml-[0.25rem]"
                    data-testid={`${currentId}-max-length`}
                  >
                    {keyList ? type[keyList] : type}
                  </p>
                </RadioButton>
              ))}
            </div>
          )}
        </div>
      </div>
      {maxSelection !== 1 && (
        <div
          className={`flex gap-[0.25rem] flex-wrap ${
            isHorizontal ? "ml-[8.75rem]" : ""
          } ${widthClass}`}
        >
          {(currentSelected || [])?.map((filter: any, index: number) => (
            <SelectedTag
              name={keyList ? filter?.[keyList] : selected}
              removeSelected={() => removeSelected(filter?.id)}
              id={`selected-${index}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
export type { IItemDropdown };
