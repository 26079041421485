import { useQuery } from "@tanstack/react-query";
import apiClient from "../hooks/apiClient";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import Select from "react-dropdown-select";
import SVGIcon from "./SVGIcon";

import "./SearchDropdown.scss";
import Button from "./Button";
import ctl from "@netlify/classnames-template-literals";
import { useEffect, useMemo, useState } from "react";
import Shimmer from "./Shimmer";
import useDebounce from "../hooks/useDebounce";

interface ISelectedDropdown {
  organizationName?: string;
  name: string;
  id: string;
  code?: string;
  refCode?: string;
}

interface ISearchDropdown {
  disabled?: boolean;
  selected: ISelectedDropdown | undefined | null;
  fetchData?: {
    searchUrl?: string;
    params?: any;
  };
  id: string;
  onChange: (val: ISelectedDropdown | undefined) => void;
  isRequired?: boolean;
  label?: string;
  widthClass?: string;
  onSuccess?: () => void;
  tabIndex?: number;
  addAction?: {
    text: string;
    iconName: string;
    onClick: (searchValue: string) => void;
  };
  additionalQueryKey?: string[];
  placeholder?: string;
  initialData?: any;
  setDropdownDataList?: (val: any) => void;
  keyData?: string;
  isHorizontal?: boolean;
  labelCustomClass?: string;
  wrapperCustomClass?: string;
  keyItem?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  insideElement?: JSX.Element | React.ReactNode;
  useClearButton?: boolean;
  useSearchValueToUrl?: boolean;
  maxOption?: number | null;
  ableToAddNewOption?: boolean;
}

const SearchDropdown = ({
  disabled,
  selected,
  fetchData,
  id,
  onChange,
  isRequired,
  label,
  widthClass = "",
  onSuccess,
  tabIndex,
  addAction,
  isHorizontal = false,
  labelCustomClass,
  wrapperCustomClass,
  keyItem = "name",
  additionalQueryKey,
  placeholder,
  initialData = null,
  setDropdownDataList,
  keyData,
  errorMessage,
  showErrorMessage,
  insideElement,
  useClearButton,
  useSearchValueToUrl = false,
  maxOption = 4,
  ableToAddNewOption = false,
}: ISearchDropdown) => {
  const [searchValue, setSearchValue] = useState("");
  const getData = async () => {
    if (!fetchData?.searchUrl) return;
    const _searchUrl = useSearchValueToUrl
      ? `${fetchData?.searchUrl}/${searchValue}`
      : fetchData?.searchUrl;
    return apiClient(_searchUrl, "GET", {
      params: fetchData?.params,
      isShowErrorToast: false,
    });
  };

  const generateQueryKey = () => {
    const _key = [id];
    additionalQueryKey && _key.push(...additionalQueryKey);
    useSearchValueToUrl && !isEmpty(searchValue) && _key.push(searchValue);
    return _key;
  };

  const memoQueryKey = useMemo(
    () => generateQueryKey(),
    [additionalQueryKey, searchValue]
  );
  const debouncedMemoQueryKey = useDebounce(memoQueryKey, 500);
  const isEnableQuery = useMemo(
    () =>
      !disabled &&
      !initialData &&
      !!fetchData &&
      (useSearchValueToUrl ? !isEmpty(searchValue) : true),
    [disabled, initialData, fetchData, useSearchValueToUrl, searchValue]
  );

  const { data: dataQuery, isFetching } = useQuery({
    queryKey: debouncedMemoQueryKey,
    queryFn: getData,
    staleTime: 5 * 1000,
    gcTime: 5 * 1000,
    enabled: isEnableQuery,
  });
  const _dataQuery = initialData ? initialData : dataQuery?.data;
  const list = (keyData ? _dataQuery?.[keyData] : _dataQuery) || [];
  const onSearch = ({ props, state, methods }: any) => {
    const _seachValue = state.search?.toLowerCase();
    setSearchValue(_seachValue);

    if (useSearchValueToUrl) return list;
    let searchResult = cloneDeep(list)?.filter((item: ISelectedDropdown) =>
      //@ts-ignore
      item?.[keyItem]?.toLowerCase()?.includes(_seachValue)
    );
    searchResult = searchResult?.slice(0, 4);
    return searchResult;
  };

  const _onChange = (values: ISelectedDropdown[]) => {
    if (isEqual(selected, values?.[0])) return;
    onChange(values?.[0]);

    onSuccess && onSuccess();
  };

  const customDropdownHandleRenderer = (props: any) => {
    const { isOpen } = props;

    return (
      <div className="mt-[4px]">
        {useClearButton && (
          <SVGIcon
            iconName="icon-cancel"
            size={16}
            fillColor="var(--n-300)"
            onClick={(e) => {
              e.stopPropagation();
              onChange(undefined);
              setSearchValue("");
              props?.methods?.clearAll && props?.methods?.clearAll();
              props.state.search = "";
            }}
            customClass="mr-[0.5rem]"
          />
        )}

        <SVGIcon
          iconName={isOpen ? "icon-arrow-up" : "icon-arrow-down"}
          size={16}
          fillColor="var(--n-300)"
          onClick={props.onClick}
        />
      </div>
    );
  };

  const setPosition = () => {
    const el = document.getElementsByName(id)?.[0];
    const rect = el?.getBoundingClientRect();
    var d = document.getElementsByClassName(
      "react-dropdown-select-dropdown"
    )?.[0];
    // @ts-ignore
    d.style.top = rect?.top + 24 + "px";
    // @ts-ignore
    d.style.left = rect?.left - 12 + "px";
  };

  const customLoadingDropdownRenderer = () => {
    return (
      <Shimmer
        customClass="m-[0.5rem]"
        heightClass="h-[1rem]"
        widthClass="w-[calc(100%-1rem)]"
      />
    );
  };

  const customDropdownRenderer = ({ props, state, methods }: any) => {
    const options =
      state?.search === "" || useSearchValueToUrl
        ? props?.options
        : state?.searchResults;

    const isShowAnotherOption = ableToAddNewOption && !!state?.search;
    const getPlaceholder = () => {
      if (isShowAnotherOption) return state?.search;
      if (useSearchValueToUrl) return "Please type your keyword first";
      return "No Option";
    };

    const PlaceholderCN = ctl(`
    typography-h200 px-[0.625rem] py-[0.5rem] ${
      useSearchValueToUrl && !isShowAnotherOption ? "text-n-400" : "text-n-700"
    }
    ${isShowAnotherOption && "cursor-pointer"}
  `);

    return (
      <>
        {addAction?.text && (
          <div className="w-full flex items-center justify-center py-[0.25rem]">
            <Button.Secondary
              id="add"
              customClass="py-[0.5rem] px-[1rem] gap-[0.25rem]"
              typographyClass="typography-h200-bold"
              onClick={() => addAction?.onClick(state?.search)}
              noPadding={true}
            >
              {addAction?.iconName && (
                <SVGIcon
                  iconName={addAction?.iconName}
                  size={16}
                  fillColor="var(--b-400)"
                />
              )}
              {addAction?.text}
            </Button.Secondary>
          </div>
        )}
        {options?.length === 0 && (
          <div
            className={PlaceholderCN}
            onClick={() =>
              ableToAddNewOption &&
              methods.addItem({ [keyItem]: state?.search })
            }
          >
            {getPlaceholder()}
          </div>
        )}
        {options?.map((opt: any, index: number) => (
          <span
            role="option"
            aria-selected={state?.cursor === index ? "true" : "false"}
            aria-label="search-dropdown"
            tabIndex={-1}
            className={`typography-h200 text-n-700 px-[0.625rem] py-[0.5rem] hover:bg-b-100 w-full cursor-pointer ${
              state?.cursor === index ? "bg-b-100" : ""
            }`}
            onClick={() => methods.addItem(opt)}
            data-testid={`${id}+option-${index}`}
          >
            {opt?.[keyItem]}
          </span>
        ))}
      </>
    );
  };

  const InputCN = ctl(`
    rounded-[10px] h-[2.625rem]  p-[0.625rem]
    border border-solid rounded-[0.75rem] border-n-300
    ${widthClass}
    ${disabled ? "bg-n-100 opacity-100 text-n-500" : "bg-n-000"}
  `);

  useEffect(() => {
    dataQuery?.isSuccess &&
      !isFetching &&
      setDropdownDataList &&
      setDropdownDataList(dataQuery?.data);
  }, [dataQuery?.data, dataQuery?.isSuccess, isFetching]);

  const wrapperCN = ctl(`
  ${isHorizontal && "flex"}
  ${isHorizontal ? "items-start" : "items-center"}
  ${isHorizontal && label && "gap-[0.75rem]"}
  ${wrapperCustomClass}
`);

  const labelCN = ctl(`
    typography-h200-bold text-n-700 mb-[0.25rem]
    ${isHorizontal && !isRequired && "ml-[0.5rem]"}
  `);

  const wrapperLabel = ctl(`
    flex gap-[0.25rem] 
    h-fit items-center 
    ${labelCustomClass}
    ${isHorizontal && "mt-[0.75rem]"}
  `);

  return (
    <div className={wrapperCN}>
      <div className={wrapperLabel}>
        {isRequired && (
          <div
            className="h-[0.5rem] w-[0.5rem] rounded-full bg-r-400 mb-[0.25rem]"
            data-testid={`${id}-required-icon`}
          />
        )}
        {label && (
          <p className={labelCN} data-testid={`${id}-label`}>
            {label}
          </p>
        )}
      </div>
      <div
        className={`flex relative ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        data-testid={id}
      >
        <Select
          searchBy={keyItem}
          labelField={keyItem}
          valueField="id"
          //@ts-ignore
          values={selected?.[keyItem] ? [selected] : []}
          options={
            maxOption !== null && maxOption > 0
              ? list?.slice(0, maxOption)
              : list
          }
          onChange={(values) => _onChange(values)}
          dropdownHandleRenderer={customDropdownHandleRenderer}
          className={InputCN}
          searchFn={onSearch}
          //@ts-ignore
          portal={document.getElementById("shared-dropdown")}
          additionalProps={{ tabIndex, id }}
          name={id}
          dropdownPosition="auto"
          dropdownGap={0}
          onDropdownOpen={setPosition}
          dropdownRenderer={
            isFetching ? customLoadingDropdownRenderer : customDropdownRenderer
          }
          disabled={disabled}
          placeholder={placeholder}
        />
        {insideElement && insideElement}
      </div>
      {errorMessage && showErrorMessage && (
        <p
          className="typography-h100 text-r-400 mt-[0.25rem]"
          data-testid={`${id}-error-message`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default SearchDropdown;
export type { ISearchDropdown, ISelectedDropdown };
